import { Link } from 'react-router-dom'
import './Ressources.scss'
import React, { useState } from 'react'
import {
  faArrowRight,
  faCircleArrowRight,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CustomAlert from '../alert/CustomAlert'

function Ressources({ asCTA }) {
  const macro_google_id =
    'https://script.google.com/macros/s/AKfycbxFnp_LB3VD2jQmC_g785T_1t9VCg_bcQkA3F1L8v3DR2ZBNf6Yov6KKWaS2XlctnO5/exec'

  const [email, setEmail] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(true)
  const [isUserSubscribe, setIsUserSubscribe] = useState(false)

  const [alertConfig, setAlertConfig] = useState({
    open: false,
    severity: '',
    message: '',
    icon: '',
  })

  const resourcesList = [
    {
      id: 1,
      title:
        '🎥 Comprendre la Neuro Atypie : Tout ce que tu as besoin de savoir ! 🌟',
      description:
        "Dans cette vidéo, nous explorons la neuro atypie : ce que c'est et ce que ce n'est pas. \n Découvre sur certains points ce que dit la recherche scientifique, qui est concerné par ce terme, et la différence entre un individu neurotypique et neuroatypique. \n Nous expliquons pourquoi il est crucial de comprendre son fonctionnement si tu es concerné.",
      videoUrl:
        'https://www.youtube.com/embed/K0vxf6N0xXQ?si=fye1_YWLIkh56ltQ;controls=0',
    },
    {
      id: 2,
      title:
        '🎥 Hyperstimulation Sensorielle : Comprendre et Reconnaître les Manifestations 🌟',
      description:
        "Comment reconnaître les manifestations de ton atypie ? \n\n Dans cette vidéo, nous explorons les manifestations de l'hyperstimulation sensorielle chez les personnes qui ne sont pas conscientes de cette caractéristique. \n Découvrez les différentes sources d'inconfort et les manifestations variées liées à l'hyperstimulabilité sensorielle. \n Apprenez à identifier les signes pour mieux comprendre et gérer cette sensibilité au quotidien. \n\n 🔍 Thèmes abordés : \n 1. Sources d'inconfort sensoriel \n 2. Manifestations courantes de l'hyperstimulation sensorielle",
      videoUrl: 'https://www.youtube.com/embed/sN-mWclm6-c?si=YObCkFNuihLT-Vgb',
    },
    {
      id: 3,
      title:
        '🎥 Hyperstimulation intellectuelle : Comprendre et Reconnaître les Manifestations 🌟',
      description:
        "Dans cette vidéo, nous explorons les manifestations de l'hyperstimulation intellectuelle chez les personnes qui ne sont pas conscientes de cette caractéristique. Découvre les différentes sources d'inconfort et les manifestations variées liées à l'hyperstimulabilité intellectuelle. \n Apprends à identifier les signes pour mieux comprendre et gérer cette sensibilité au quotidien. \n\n 🔍 Thèmes abordés : \n 1. Sources d'inconfort lié à l’hyperstimulation intellectuelle \n 2. Manifestations courantes de l'hyperstimulation intellectuelle \n 3. Les impacts",
      videoUrl: 'https://www.youtube.com/embed/Js5zLa6pPIc?si=WYorVXHb-43Idypa',
    },
    {
      id: 4,
      title:
        '🎥 Hyperstimulation psychomotrice : Comprendre et Reconnaître les Manifestations 🌟',
      description:
        "Dans cette vidéo, nous explorons les manifestations de l'hyperstimulation psychomotrice chez les personnes qui ne sont pas conscientes de cette caractéristique. Découvre les différentes sources d'inconfort et les manifestations variées liées à l'hyperstimulabilité psychomotrice. Apprends à identifier les signes pour mieux comprendre et réguler cette sensibilité au quotidien. \n\n 🔍 Thèmes abordés : \n\t 1. Sources d'inconfort lié à l’hyperstimulation psychomotrice \n 2. Manifestations courantes de l'hyperstimulation psychomotrice \n 3. Les impacts aux quotidiens",
      videoUrl: 'https://www.youtube.com/embed/4I1s28L7oxI?si=bAq5xiyzp5B1-yen',
    },
    {
      id: 5,
      title: "🎥 La relation inconsciente aux codes sociaux : Comprendre et Reconnaître nos Manifestations 🌟",
      description: "Dans cette vidéo, nous explorons les manifestations inconscientes liées aux codes sociaux chez les personnes neuro atypiques. Découvre les différentes sources d'inconfort et les manifestations variées en lien avec les codes sociaux. Apprends à identifier les signes pour mieux comprendre et réguler cette difficulté au quotidien.\n\n🔍 Thèmes abordés :\n1. Sources d'inconfort\n2. Manifestations courantes\n3. Les impacts aux quotidiens",
      videoUrl: 'https://www.youtube.com/embed/yAWY3zotfvI',
    },
    {
      id: 6,
      title: "🎥 L'idéalisme : Comprendre et Reconnaître les Manifestations 🌟",
      description: "Dans cette vidéo, nous explorons les manifestations de l'idéalisme chez les personnes neuro atypiques qui ne sont pas conscientes de cette caractéristique. Découvre les différentes sources d'inconfort et les manifestations variées liées à l'idéalisme. Apprends à identifier les signes pour mieux comprendre et gérer cette sensibilité au quotidien.\n\n🔍 Thèmes abordés :\n1. Sources d'inconfort lié à l'idéalisme\n2. Manifestations courantes de l'idéalisme\n3. Les impacts au quotidien.",
      videoUrl: 'https://www.youtube.com/embed/SRfSngM8xpc',
    },
    {
      id: 7,
      title: "🎥 Tes manifestations Émotionnelles : Ce que les Neurotypiques voient et ne comprennent pas ? 🌟",
      description: "Dans cette vidéo, nous explorons ce que les gens qui vivent avec un neuro atypique, perçoivent de ses manifestations émotionnelles. D'autant plus si ces personnes n'ont pas connaissance de cette caractéristique. Découvre les différentes interprétations qui peuvent découler de tes manifestations variées liées à ta haute émotionnalité (HSP). Cette vidéo permet d'apprendre à identifier les signes pour mieux comprendre, accepter et accueillir cette sensibilité au quotidien.\n\n🔍 Thèmes abordés :\n1. Manifestations liées à ton émotionnalité intense.\n2. Ce que les personnes extérieures peuvent se raconter ou interpréter.\n3. Les impacts au quotidien.",
      videoUrl: 'https://www.youtube.com/embed/H-40iTlF7iE',
    },
    {
      id: 8,
      title:
        '🎥 Tes manifestations en lien avec ton hyperstimulation intellectuelle : Ce que les Neurotypiques voient et ne comprennent pas ? 🌟',
      description:
        "Dans cette vidéo, nous explorons ce que les gens qui vivent avec un neuro atypique, perçoivent de ses manifestations en lien avec son hyperstimulation intellectuelle. D'autant plus si ces personnes n'ont pas connaissance de cette caractéristique. \n Découvre les différentes interprétations qui peuvent découler de tes manifestations variées liées à ton hyperstimulation intellectuelle (HPI). Cette vidéo permet d'apprendre à identifier les signes pour mieux comprendre, accepter et accueillir cette sensibilité au quotidien. \n\n 🔍 Thèmes abordés : \n\t 1. Manifestations liées à ton hyperstimulation intellectuelle. \n 2. Ce que les personnes extérieures peuvent se raconter ou interpréter. \n 3. Les impacts au quotidien.",
      videoUrl: 'https://www.youtube.com/embed/3xkzAohjVg8',
    },
    {
      id: 9,
      title: "🎥 Ton hyperstimulabilité psychomotrice : Ce que les Neurotypiques voient et ne comprennent pas ? 🌟",
      description: "Dans cette vidéo, nous explorons ce que les gens qui vivent avec un neuro atypique, perçoivent de ses manifestations en lien avec son hyperstimulation psychomotrice. D'autant plus si ces personnes n'ont pas connaissance de cette caractéristique. Découvre les différentes interprétations qui peuvent découler de tes manifestations variées liées à ton hyperstimulabilité psychomotrice (HPI, TDAH). Cette vidéo permet d'apprendre à identifier les signes pour mieux comprendre, accepter et accueillir cette sensibilité au quotidien.\n\n🔍 Thèmes abordés :\n1. Manifestations liées à ton hyperstimulabilité psychomotrice.\n2. Ce que les personnes extérieures peuvent se raconter ou interpréter.\n3. Les impacts au quotidien.",
      videoUrl: 'https://www.youtube.com/embed/Zaur9BLxLE4',
    },
    {
      id: 10,
      title: "🎥 L'idéalisme : Ce que les Neurotypiques voient et ne comprennent pas ? 🌟",
      description: "Dans cette vidéo, nous explorons ce que les gens qui vivent avec un neuro atypique, perçoivent de ses manifestations en lien avec son idéalisme. D'autant plus si ces personnes n'ont pas connaissance de cette caractéristique. Découvre les différentes interprétations qui peuvent découler de tes manifestations variées liées à ton idéalisme. Cette vidéo permet d'apprendre à identifier les signes pour mieux comprendre, accepter et accueillir cette sensibilité au quotidien.\n\n🔍 Thèmes abordés :\n1. Manifestations liées à ton idéalisme.\n2. Ce que les personnes extérieures peuvent se raconter ou interpréter.\n3. Les impacts au quotidien.",
      videoUrl: 'https://www.youtube.com/embed/xNgOV61y1kk',
    },
    {
      id: 11,
      title: '🎥 La neuro atypie et les codes sociaux : Ce que les Neurotypiques voient et ne comprennent pas ? 🌟',
      description: "Dans cette vidéo, nous explorons ce que les gens qui vivent avec un neuro atypique, perçoivent de ses manifestations en lien avec les codes sociaux. D'autant plus si ces personnes n'ont pas connaissance de cette caractéristique. Découvre les différentes interprétations qui peuvent découler de tes manifestations liées à ta relation avec les codes sociaux. Cette vidéo permet d'apprendre à identifier les signes pour mieux les comprendre, accepter et accueillir au quotidien.\n\n🔍 Thèmes abordés :\n1. Manifestations liées à ta relation aux codes sociaux.\n2. Ce que les personnes extérieures peuvent se raconter ou interpréter.\n3. Les impacts au quotidien.",
      videoUrl: 'https://www.youtube.com/embed/7CBnwdzIZjs',
    },
    {
      id: 12,
      title: '🎥 Sensorialité ? Et si ce que tu considères comme une faiblesse était en fait ta plus grande force ? 🌟',
      description: "Et si ta sensibilité, que tu considères souvent comme une faiblesse, était en réalité ta plus grande force ? Dans cette vidéo, découvre l'histoire de Téo, un manager qui a accepté une promotion de rêve mais qui, très vite, s'est retrouvé épuisé et submergé par ses émotions et son environnement.\n\nAu fil de son parcours, Téo réalise qu'il vit avec une hyperstimulabilité sensorielle qui, loin d'être un fardeau, peut devenir son plus grand atout. Grâce à un coaching personnalisé, il apprend à transformer sa sensibilité en force et à mieux gérer son quotidien professionnel.\n\n👉 Si tu te reconnais dans cette histoire, que tu ressens souvent que les sons, les lumières, ou les émotions des autres t'épuisent, cette vidéo est pour toi.\n\n🔎 Dans cette vidéo, tu découvriras :\n• Comment Téo a découvert son hypersensorialité et l'a transformée en force.\n• Les difficultés concrètes qu'il a rencontrées dans son nouveau poste de manager (stress, surcharge sensorielle, fatigue chronique).\n• Les solutions qu'il a mises en place pour retrouver son énergie et exceller dans son rôle.\n• Des conseils pratiques pour apprendre à régule ta propre hypersensorialité et en faire un atout dans ta vie quotidienne.",
      videoUrl: 'https://www.youtube.com/embed/FMak8xKHj5Q',
    },
    {
      id: 13,
      title:
        '🎥 Neuro atypique, Ressentir intensément les émotions des autres te rend-il plus fort ou au contraire plus vulnérable ? 🌟',
      description:
        "Et si ton hyper stimulation émotionnelle, que tu considères souvent comme de la vulnérabilité, était en réalité ta plus grande force ? Dans cette vidéo, découvre l'histoire de Greg, un manager dévoué qui se retrouve dépassé par son hyperstimulabilité émotionnelle. \n\n Tu te reconnais dans cette description ? Reste avec moi pour découvrir comment il a transformé cette caractéristique en une véritable force ! Grâce à un accompagnement adapté, Greg a appris à poser des limites et à utiliser son empathie pour devenir un leader inspirant. \n\n Tu apprendras également des conseils pratiques pour gérer tes propres émotions, que tu sois manager ou non. \n\n 👉 Si tu te reconnais dans cette histoire, cette vidéo est pour toi. \n\n 🔎 Dans cette vidéo, tu découvriras : \n 1. Comment Greg a découvert son hyperstimulation émotionnelle et l'a transformée en force. \n 2. Les difficultés concrètes qu'il a rencontrées. \n 3. Les solutions qu'il a mises en place pour retrouver son énergie et exceller dans son rôle. \n 4. Des conseils pratiques pour apprendre à réguler ta propre hyperstimulabilité émotionnelle et en faire un atout dans ta vie quotidienne.",
      videoUrl: 'https://www.youtube.com/embed/AJ8BhejXsqU',
    },
    {
      id: 14,
      title:
        '🎥 Hyperstimulabilabilité intellectuelle : Comment j\'ai appris à réguler mon cerveau hyperactif ! 🌟',
      description:
        "Dans cette vidéo, avec Stéphane, nous te partageons mon expérience personnelle liée à mon hyperstimulabilité intellectuelle qui m'a conduite à une surcharge cognitive et à un stress intense. \n Mon histoire résonnera sûrement avec toi si tu te reconnais dans cette course effrénée pour toujours en savoir plus, exceller et repousser tes limites intellectuelles.\n Accompagnée de Stéphane, je te dévoile mon cheminement vers la découverte de mon hyperstimulabilité intellectuelle et la manière dont j'ai appris à transformer cette caractéristique en force plutôt qu'en fardeau.",
      videoUrl: 'https://www.youtube.com/embed/XWC9UIOvZ84',
    }
  ]
  
  function getYoutubeIdFromUrl(url) {
    const youtubeRegex =
      /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
    const match = url.match(youtubeRegex)
    return match ? match[1] : null
  }
  <iframe width="1255" height="706" src="https://www.youtube.com/embed/AJ8BhejXsqU" title="Neuro atypique, Ressentir intensément les émotions des autres te rend-il fort ou vulnérable ?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
  const handleRedirect = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  function handleInput(event) {
    setIsEmailValid(true)
    setIsUserSubscribe(false)
    setEmail(event.target.value)
  }

  async function handleSubmit(event) {
    event.preventDefault()

    if (email === '' || !/\S+@\S+\.\S+/.test(email)) {
      setIsEmailValid(false)
      setAlertConfig({
        open: true,
        severity: 'error',
        message: "Cet email n'est pas valide 🙄",
        icon: 'error',
      })
    } else {
      try {
        const response = await fetch(macro_google_id, {
          redirect: 'follow',
          method: 'POST',
          body: JSON.stringify({ email }),
          headers: { 'Content-Type': 'text/plain;charset=utf-8' },
        })
        if (response.ok) {
          setIsUserSubscribe(true)
          setAlertConfig({
            open: true,
            severity: 'success',
            message: 'Merci ! Tu va recevoir par email tous tes documents 😊',
            icon: 'success',
          })
        } else {
          setIsUserSubscribe(false)
          setAlertConfig({
            open: true,
            severity: 'error',
            message: 'Il y a eu une erreur. Merci de tenter à nouveau.',
            icon: 'error',
          })
        }
      } catch (error) {
        setIsUserSubscribe(false)
        setAlertConfig({
          open: true,
          severity: 'error',
          message: 'Il y a eu une erreur. Merci de tenter à nouveau.',
          icon: 'error',
        })
      }
      setIsEmailValid(true)
      setEmail('')
    }
  }

  const subscribeButton = (
    <div
      className="g-ytsubscribe"
      data-channel="DelPfine-coaching"
      data-layout="default"
      data-count="default"
    ></div>
  )

  if (asCTA) {
    // Si asCTA est true, afficher seulement le premier élément comme CTA
    const primaryResource = resourcesList[resourcesList.length - 1]

    return (
      <div className="ressource_container">
        <div className="ressource_content">
          <div className="sub_container_light">
            <div className="mb-2 text-align-center">
              <h2>Les ressources</h2>
              <div className="sub_container_light video-wrapper">
                <h3>{primaryResource.title}</h3>
                {primaryResource.videoUrl.length > 0 && (
                  <iframe
                    className="video"
                    src={primaryResource.videoUrl}
                    title={primaryResource.title}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  ></iframe>
                )}
              </div>
              <div className="cta-container m-2">
                <Link
                  to="/ressources"
                  className="cta-link"
                  onClick={() => handleRedirect()}
                >
                  <FontAwesomeIcon icon={faCircleArrowRight} /> ACCEDER À TOUTES
                  LES RESSOURCES{' '}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    // Sinon, afficher toutes les ressources
    return (
      <div className="ressource_container">
        <div className="ressource_content">
          <div className="mb-2 text-align-center">
            <div className="mb-3">
              <h1>Les ressources</h1>
              <h2>LES CARACTÉRISTIQUES COMMUNUES AUX NEURO ATYPIQUES ?</h2>
              <div className="sub_container_light">
                <p>
                  Tu es HPI, haut potentiel intellectuel, hypersensible, zèbre,
                  coloré, surdoué (e), philo-cognitif., ces vidéos sont pour toi
                  si tu veux comprendre ton fonctionnement ou celui de quelqu’un
                  qui t’est proche.
                </p>
              </div>
              {/* <div className="sub_container_light">
                <h3>Débloque ton accès pour les vidéos offertes </h3>
                <p>
                  Alors si tu t’ <strong>INTERROGES</strong>, si tu veux{' '}
                  <strong>COMPRENDRE</strong>, y<strong> VOIR</strong> plus
                  clair.
                </p>
                <p>N’hésite surtout pas !</p>
                <a
                  href="https://www.calendly.com/alexandre-morel69/decouverte"
                  className="btn_rervervation"
                >
                  <p>Je veux recevoir mon accès offert ! </p>
                </a>
              </div> */}
              <div className="sub_container_light free_download">
                <h3>Reçois gratuitement</h3>
                <div className="text_receve_docs">
                  <p>
                    La présentation Agile Lyon 2024 "Ce n'est pas du cinéma ! Je
                    suis Neuro Atypique !"
                  </p>
                  <p>+</p>
                  <p> les résultats du test</p>
                </div>
                <form onSubmit={handleSubmit}>
                  <input
                    type="email"
                    placeholder="E-mail"
                    value={email}
                    onChange={handleInput}
                    style={{
                      padding: '10px',
                      fontSize: '16px',
                      border: '2px solid #ddd',
                      borderRadius: '12px',
                    }}
                  />
                  {/* 
                  <p className="sub_sub_text">
                    * En communicant mon e-mail j'accepte de recevoir vos
                    communications et confirme avoir pris connaissance de votre
                    politique de confidentialité et mentions légales
                  </p> */}
                  <button type="submit" className="btn_rervervation">
                    <p>Je veux recevoir mes ressources offertes !</p>
                  </button>
                </form>
                {!isEmailValid ? <p>Cet email n'est pas valide 🙄</p> : null}
                {isUserSubscribe ? (
                  <p>
                    Merci ! Tu va recevoir les ressources sur ton email dans les
                    minutes qui viennent 😊
                  </p>
                ) : null}
              </div>
            </div>
            {resourcesList.map((resource) => (
              <div
                key={resource.id}
                className="sub_container_light video-wrapper"
              >
                <h3>{resource.title}</h3>
                {resource.description.length > 0 && (
                  <p
                    className="sub_text"
                    dangerouslySetInnerHTML={{
                      __html: resource.description.replace(/\n/g, '<br />'),
                    }}
                  ></p>
                )}
                {resource.videoUrl.length > 0 && (
                  <iframe
                    className="video"
                    src={resource.videoUrl}
                    title={resource.title}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  ></iframe>
                  // <a
                  //   href={resource.videoUrl}
                  //   target="_blank"
                  //   rel="noopener noreferrer"
                  //   className="video-thumbnail-link"
                  // >
                  //   <img
                  //     src={`https://img.youtube.com/vi/${getYoutubeIdFromUrl(
                  //       resource.videoUrl
                  //     )}/mqdefault.jpg`}
                  //     alt={resource.title}
                  //     className="video-thumbnail"
                  //   />
                  // </a>
                )}
              </div>
            ))}
            {subscribeButton}
          </div>
        </div>
        <CustomAlert
          openAlert={alertConfig.open}
          onClose={() => setAlertConfig({ ...alertConfig, open: false })}
          severity={alertConfig.severity}
          icon={alertConfig.icon}
        >
          {alertConfig.message}
        </CustomAlert>
      </div>
    )
  }
}

export default Ressources